<template>
  <div>
    <h3 ref="intro">
      What is a Triangle?
    </h3>
    <br>
    <p>
      A triangle is a shape that is obtained by connecting three points on the same plane with straight lines. <br><br>

      The three points are called vertices of the triangle, and three straight lines
      connecting these points are called sides of the triangle.
    </p>
    <h3 ref="intro">
      Area of a Triangle
    </h3>
    <br>
    <p>
      The area of a triangle is the span of the region enclosed between the three sides of the triangle.
      <br> <br>
      For a triangle with height \(h\) and base \(b\), the area is given by
      $$A = \frac{1}{2} \times b \times h$$
    </p>

    <h3 ref="pg">
      MagicGraph | Area of a Triangle
    </h3>
    <br>
    <h5> Getting Started </h5>
    <p>
      This MagicGraph offers a visual-interactive illustration that explains why the area of a triangle is \(bh/2\).
      <br><br>
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      Tap on <i class="redoer ma-1" /> button  to go to the next step. Tap on <i class="undoer ma-1" /> button to go to the previous step.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'TriangleArea',
  created () {
    this.$store.commit('navigation/resetState');
    let title = 'Area of a Triangle';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    /*let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);*/
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Area of a Triangle',
        //  titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {vmid:'description',name: 'description', content: 'Learn interactively about area of a triangle'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
